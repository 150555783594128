.mainvisual {
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  position: relative;
}
.mainvisual::before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.mainvisual__pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.mainvisual__desc {
  position: relative;
  z-index: 2;
}
.mainvisual__desc .title {
  max-width: 580px;
  font-family: 'Raleway', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',
    sans-serif;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.16em;
  word-break: break-all;
}
.mainvisual__desc span {
  width: 240px;
  height: 1px;
  display: block;
  margin-top: 16px;
  margin-bottom: 24px;
}
.mainvisual__desc p {
  max-width: 580px;
  font-size: 14px;
  letter-spacing: 0.1em;
  word-break: break-all;
}
.mainvisual__scroll {
  width: 64px;
  font-family: 'Raleway', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',
    sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  left: calc(50% - 32px);
  bottom: 0px;
  z-index: 2;
}
.mainvisual__scroll__txt {
  position: absolute;
  left: 5px;
  top: 50px;
  z-index: 3;
}
.mainvisual__scroll span {
  width: 1px;
  height: 96px;
  background: #2e1f12;
  margin: 4px auto 0 auto;
  overflow: hidden;
  display: block;
  position: relative;
}
.mainvisual__scroll span::after {
  content: '';
  width: 1px;
  height: 32px;
  background: #fff;
  display: block;
  position: absolute;
  left: 0;
  animation: scroll 1.6s linear infinite;
}
@keyframes scroll {
  0% {
    top: -32px;
  }
  100% {
    top: 100%;
  }
}
@media screen and (max-width: 768px) {
  .mainvisual {
    height: 360px;
    padding-bottom: 16px;
    align-items: flex-end;
  }
  .mainvisual__desc {
  }
  .mainvisual__desc h2 {
    font-size: 24px;
  }
  .mainvisual__desc span {
    width: 120px;
    margin-top: 12px;
    margin-bottom: 16px;
  }
  .mainvisual__desc p {
    font-size: 12px;
  }
  .mainvisual__scroll {
    display: none;
  }
}
